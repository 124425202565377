import React, { useEffect, useState } from "react"
import * as Contents from "./contents"
import Header from "."
import Hamburger from "../icons/hamburger"
import { between, down } from "styled-breakpoints"
import useBreakpoint from "../../hooks/useBreakpoint"
import useIsClient from "../../hooks/useIsClient"
import { useProgress } from "../../hooks/useProgress"
import ContinueLink from "../continue-link"
import styled from "styled-components"
import { CenterContent } from "./styles"

interface Props {
  divider?: boolean
  omitLeftContent?: boolean
}

const BasicHeader: React.FC<Props> = ({ divider, omitLeftContent }) => {
  const { isClient } = useIsClient()
  const [progress] = useProgress()

  const isMobile = useBreakpoint(down("md"))
  const isTablet = useBreakpoint(between("sm", "lg", "portrait"))
  const [isClosed, setIsClosed] = useState(true)

  const toggleClosed = () => setIsClosed(prev => !prev)

  useEffect(() => {
    if (!isMobile && !isTablet) setIsClosed(true)
  }, [isMobile, isTablet])

  const leftContent = isClient
    ? !omitLeftContent && <Contents.ChapterLeftContent />
    : null

  const centerContent = isClient
    ? !isMobile && !isTablet && <Contents.BasicCenterContent />
    : null

  const rightContent = isClient
    ? (isMobile || isTablet) && (
        <Hamburger
          transform={!isClosed}
          onClick={toggleClosed}
          type="button"
          aria-controls="header-dropdown-menu"
          aria-expanded={!isClosed}
        />
      )
    : null

  return (
    <Header
      divider={divider}
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={
        <>
          {isClient && progress.completion > 0 ? <ContinueLink /> : null}
          {rightContent}
        </>
      }
      dropdownLayerVisible={!isClosed}
      dropdownLayerContent={<Contents.BasicDropdownItems />}
      CenterContentWrapper={StyledCenterContent}
    />
  )
}

const StyledCenterContent = styled(CenterContent)`
  justify-content: center;

  & > * + * {
    margin-left: 4rem !important;
  }
`

export default BasicHeader
