import React, { useCallback, useEffect, useState } from "react"
import { navigate, PageProps } from "gatsby"
import Seo from "../components/seo"
import BasicHeader from "../components/header/basic"
import { ThemeProvider } from "styled-components"
import { blueTheme } from "../styles/themes"
import * as Styled from "../styles/pages/home"
import GlobalStyles from "../styles/globals"
import Divider from "../components/divider"
import { down } from "styled-breakpoints"
import { AnimatePresence, motion } from "framer-motion"
import useBreakpoint from "../hooks/useBreakpoint"
import { useHistoryContext } from "../context/history"
import useIsClient from "../hooks/useIsClient"
import ContinueLink, { StartReadingLink } from "../components/continue-link"
import useStoredState from "../hooks/useStoredState"
import { useProgress } from "../hooks/useProgress"

import ArrowRight from "../images/arrow-right.svg"
import CoverVertical from "../images/cover-vertical.svg"
import CoverHorizontal from "../images/cover-horizontal.svg"

const IndexPage: React.FC<PageProps> = () => {
  const [progress] = useProgress()
  const { isClient } = useIsClient()
  const { push } = useHistoryContext()

  const start = useCallback(() => {
    navigate("/introduction/start", { state: { forward: true } })
    push("/introduction/")
  }, [push])

  return (
    <>
      <GlobalStyles variant="blue" />
      <Seo title="Home" />
      <BasicHeader divider omitLeftContent />
      <ThemeProvider theme={blueTheme}>
        <GlobalStyles variant="blue" />
        <Styled.Layout>
          <Styled.Title>
            Model i metafora<Styled.Span>. </Styled.Span>
            <Styled.SubtitleSpan>
              Komunikacja wizualna w&nbsp;humanistyce
            </Styled.SubtitleSpan>
          </Styled.Title>
          {/* // ! This only shows on mobile */}
          <Styled.Subtitle>
            Komunikacja wizualna w&nbsp;humanistyce
          </Styled.Subtitle>

          <Styled.SubtitleSeparator role="separator" />

          <Styled.Author>Maja Starakiewicz</Styled.Author>
          <Styled.VisualAuthor>
            {/* // ! This only shows on laptop+ */}
            <Styled.AuthorSpan>
              Maja Starakiewicz<span>|</span>
            </Styled.AuthorSpan>
            <Styled.VisualAuthorSpan>
              Koncepcja wizualna
              <Styled.VisualAuthorDivider>:</Styled.VisualAuthorDivider>
            </Styled.VisualAuthorSpan>
            Jakub Woynarowski<Styled.HideOnMobile>,</Styled.HideOnMobile>
            <Styled.MobileBreak /> Maja Starakiewicz
          </Styled.VisualAuthor>

          <Divider
            role="separator"
            style={{ gridArea: "vc-author", alignSelf: "end" }}
          />

          <Styled.Graphic>
            <Styled.ImgVertical2
              src={CoverVertical}
              alt="Pionowa plama, siatka izometryczna i piramida z bloków"
            />
            <Styled.ImgHorizontal2
              src={CoverHorizontal}
              alt="Pozioma plama, siatka izometryczna i piramida z bloków"
            />
          </Styled.Graphic>

          <Styled.StartSection>
            {/* // ! This only shows on tablet+ */}
            <Styled.NextImg2
              src={ArrowRight}
              alt="Przejdź dalej"
              aria-label="Przejdź dalej"
              tabIndex={0}
              role="button"
              onClick={start}
            />
            {/* // ! This only shows on mobile */}
            {isClient ? (
              <Styled.ReadLink>
                {progress.completion > 0 ? (
                  <ContinueLink
                    style={{ position: "relative", visibility: "visible" }}
                  />
                ) : (
                  <StartReadingLink
                    style={{ fontSize: "1.5rem", fontWeight: 500 }}
                  />
                )}
              </Styled.ReadLink>
            ) : null}

            <Styled.BottomDivider2 role="separator" />
          </Styled.StartSection>
        </Styled.Layout>

        <Graphic />
      </ThemeProvider>
    </>
  )
}

export default IndexPage

const Graphic = () => {
  const { isClient, key } = useIsClient()

  const [isGraphicVisible, setIsGraphicVisible] = useState(true)
  const [splash, setSplash] = useStoredState("splash", false, (item: any) => {
    return typeof item === "string" ? Boolean(item) : (`${item}` as any)
  })
  const isMobile = useBreakpoint(down("md"))

  const hideGraphic = useCallback(() => {
    if (!isMobile) return

    setIsGraphicVisible(false)
  }, [isMobile])

  useEffect(() => {
    if (splash) {
      setIsGraphicVisible(false)
      return
    }

    const timeout = setTimeout(() => {
      setIsGraphicVisible(false)
      setSplash(true)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [isMobile, splash])

  if (!isClient) return null

  return (
    <AnimatePresence key={key} initial={false}>
      {isGraphicVisible && (
        <Styled.Splash
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          onClick={hideGraphic}
        >
          <Styled.ImgVertical2
            src={CoverVertical}
            alt="Pionowa plama, siatka izometryczna i piramida z bloków"
          />
          <Styled.ImageDivider2 />
        </Styled.Splash>
      )}
    </AnimatePresence>
  )
}
