import { between, down, only, up } from "styled-breakpoints"
import styled from "styled-components"
import Divider from "../../components/divider"

export const Layout = styled.main`
  display: grid;
  padding: 0 1.8rem;
  background: ${({ theme }) => theme.colors.blue};

  height: calc(100vh - var(--header-height));
  max-height: calc(100vh - var(--header-height));

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "title"
    "subtitle"
    "author"
    "vc-author"
    "start";

  ${up("md")} {
    grid-column-gap: 0.9rem;
    padding: 0 3.5rem;

    grid-template-rows: min-content min-content min-content 1fr min-content;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "title title title title"
      "author author author author"
      "vc-author vc-author vc-author vc-author"
      "graphic graphic graphic graphic"
      "start start start start";
  }

  ${up("lg")} {
    grid-template-rows: min-content min-content 1fr min-content;
    grid-template-areas:
      "title title title title"
      "vc-author vc-author vc-author vc-author"
      "graphic graphic graphic graphic"
      "start start start start";
  }

  ${up("xl")} {
    grid-column-gap: 1.6rem;
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`
export const Span = styled.span`
  ${down("md")} {
    display: none;
  }
`

export const Title = styled.h1`
  grid-area: title;
  align-self: end;

  ${props => ({
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "2.7rem",
    lineHeight: "calc(0.7 * 6rem)",
    fontWeight: "normal",
    margin: "0 0 1rem 0",

    [up("md")(props)]: {
      textAlign: "center",
      fontSize: "3.5rem",
      lineHeight: "calc(0.83 * 6rem)",
      margin: "7rem 0 3.5rem 0",
    },

    [up("xl")(props)]: {
      fontSize: "5rem",
      lineHeight: "6rem",
      margin: "11.7rem 0 5.85rem 0",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.41 * 5rem)",
      lineHeight: "calc(1.41 * 6rem)",
    },
  })};
`
export const SubtitleSpan = styled.span`
  display: none;

  ${up("md")} {
    display: inline;
  }
`

export const Subtitle = styled.h2`
  grid-area: subtitle;
  text-transform: uppercase;
  align-self: start;

  ${props => ({
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "2rem",
    lineHeight: "3.3rem",
    fontWeight: "normal",
    margin: "0",
  })};

  ${up("md")} {
    display: none;
  }
`

export const SubtitleSeparator = styled(Divider)`
  grid-area: subtitle;
  align-self: end;

  ${up("md")} {
    display: none;
  }
`

export const Author = styled.h3`
  grid-area: author;
  align-self: end;

  ${up("md")} {
    text-align: center;
  }

  ${up("lg")} {
    display: none;
  }

  ${props => ({
    textTransform: "uppercase",
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "1.5rem",
    lineHeight: "calc(0.7 * 3rem)",
    margin: "0 0 4rem 0",
    fontWeight: 500,

    [between("md", "xl")(props)]: {
      fontSize: "calc(0.83 * 2rem)",
      lineHeight: "calc(0.83 * 3rem)",
      margin: "calc(0.83 * 1rem) 0 calc(0.83 * 1rem) 0",
    },

    [only("xl")(props)]: {
      fontSize: "2rem",
      lineHeight: "3rem",
      margin: "1rem 0 1rem 0",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.41 * 2rem)",
      lineHeight: "calc(1.41 * 3rem)",
      margin: "calc(1.41 * 1rem) 0 calc(1.41 * 1rem) 0",
    },
  })};
`

export const AuthorSpan = styled.span`
  display: none;

  ${up("lg")} {
    display: inline;
  }

  span {
    margin-left: 1.45rem;
    margin-right: 1.45rem;

    ${up("xl")} {
      margin-right: 2.75rem;
      margin-left: 2.75rem;
    }
  }

  ${props => ({
    textTransform: "uppercase",
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontWeight: 500,

    [between("md", "xl")(props)]: {
      fontSize: "calc(0.83 * 2rem)",
      lineHeight: "calc(0.83 * 3rem)",
    },

    [only("xl")(props)]: {
      fontSize: "2rem",
      lineHeight: "3rem",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.41 * 2rem)",
      lineHeight: "calc(1.41 * 3rem)",
    },
  })};
`

export const VisualAuthor = styled.h3`
  grid-area: vc-author;
  align-self: start;

  ${up("md")} {
    text-align: center;
  }

  ${props => ({
    textTransform: "uppercase",
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "1.5rem",
    lineHeight: "calc(0.7 * 3rem)",
    margin: "calc(0.7 * 1rem) 0 calc(0.7 * 1rem) 0",
    fontWeight: 500,

    [between("md", "xl")(props)]: {
      fontSize: "calc(0.83 * 2rem)",
      lineHeight: "calc(0.83 * 3rem)",
      margin: "0 0 7rem 0",
    },

    [only("xl")(props)]: {
      fontSize: "2rem",
      lineHeight: "3rem",
      margin: "0 0 11.7rem 0",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.41 * 2rem)",
      lineHeight: "calc(1.41 * 3rem)",
      margin: "0 0 11.7rem 0",
    },
  })};
`

export const VisualAuthorSpan = styled.span`
  display: block;
  margin: calc(0.7 * 1rem) 0 calc(0.7 * 1rem) 0;

  ${up("md")} {
    display: inline;
  }
`

export const VisualAuthorDivider = styled.span`
  margin-right: 1rem;
`

export const VisualAuthorSeparator = styled(Divider)`
  grid-area: vc-author;
  align-self: end;

  ${up("md")} {
  }
`

export const StartSection = styled.div`
  grid-area: start;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ReadLink = styled.div`
  margin-top: auto;

  ${up("md")} {
    display: none;
  }
`

export const Graphic = styled.div`
  grid-area: graphic;
  justify-content: center;
  display: flex;
  position: relative;
  text-align: center;
  margin-top: 3.5rem;

  ${down("md")} {
    display: none;
  }

  ${up("xl")} {
    margin-top: 1.5rem;
  }
`

export const Splash = styled.div`
  max-height: initial;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 1.8rem;
  right: 1.8rem;
  z-index: 10;
  background: ${({ theme }) => theme.colors.blue};
  display: flex;
  justify-content: space-between;
  margin-top: 0;

  ${up("md")} {
    display: none;
  }
`

export const ImgVertical2 = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;

  @media (min-aspect-ratio: 1/1) {
    display: none;
  }

  ${down("md")} {
    padding-top: calc(var(--header-height) * 2);
    padding-bottom: calc(var(--header-height) * 2);
  }
`

export const ImgHorizontal2 = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;

  @media (max-aspect-ratio: 1/1) {
    display: none;
  }
`

export const ImageDivider2 = styled(Divider)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: var(--header-height);

  ${up("md")} {
    display: none;
  }
`

export const NextImg2 = styled.img`
  margin: 3.5rem auto 3.5rem auto;
  flex-shrink: 0;
  cursor: pointer;

  ${down("md")} {
    display: none;
  }

  ${up("xl")} {
    margin: 1.5rem auto 1.5rem auto;
  }
`

export const BottomDivider2 = styled(Divider)`
  margin-top: auto;
  margin-bottom: var(--header-height);

  ${up("md")} {
    margin-bottom: calc(var(--header-height) / 2);
    margin-top: calc(var(--header-height) / 2);
  }
`

export const MobileBreak = styled.br`
  ${up("md")} {
    display: none;
  }
`

export const HideOnMobile = styled.span`
  ${down("md")} {
    display: none;
  }
`
